import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { IRouteName } from '@/router/interfaces';
import NotFoundComponent from '@/components/NotFoundComponent.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/main/Main.vue'),
    children: [
      {
        path: `/${IRouteName.Dashboard}`,
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },

      {
        path: `/${IRouteName.Items}`,
        component: () => import('@/views/items/Items.vue'),
      },
      {
        path: `/${IRouteName.Orders}`,
        component: () => import('@/views/orders/Orders.vue'),
        children: [
          {
            path: `/${IRouteName.Order_Detail}/:id`,
            component: () => import('@/views/orders/pages/OrderDetail.vue'),
            props: true,
          },

          {
            path: `/${IRouteName.Orders}`,
            component: () => import('@/views/orders/pages/NewOrder.vue'),
          },
          {
            path: `/${IRouteName.Orders}/${IRouteName.NotStarted}`,
            component: () => import('@/views/orders/pages/NotStartedOrder.vue'),
          },
          {
            path: `/${IRouteName.Orders}/${IRouteName.Start}`,
            component: () => import('@/views/orders/pages/StartedOrder.vue'),
          },
          {
            path: `/${IRouteName.Orders}/${IRouteName.Delivery}`,
            component: () => import('@/views/orders/pages/DeliveryOrder.vue'),
          },
        ],
      },
      {
        path: `/${IRouteName.Customers}`,
        component: () => import('@/views/customers/Customers.vue'),
      },
      {
        path: `/${IRouteName.Customers}/:id`,
        component: () => import('@/views/customers/pages/CustomerDetail.vue'),
        props: true,
      },
    ],
  },

  {
    path: `/${IRouteName.AddItem}`,
    name: IRouteName.AddItem,
    component: () => import('@/views/add-item/AddItem.vue'),
  },

  {
    path: `/${IRouteName.EditItem}/:id`,
    name: IRouteName.EditItem,
    component: () => import('@/views/edit-item/EditItem.vue'),
    props: true,
  },

  {
    path: `/${IRouteName.Auth}`,
    name: IRouteName.Auth,
    component: () => import('@/views/auth/Authorization.vue'),
  },
  { path: '/:pathMatch(.*)', component: NotFoundComponent },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
