import router from '@/router';
import { IRouteName } from '@/router/interfaces';
import { useToast } from 'vue-toastification';

export const checkAuthStatus = () => {
  const toast = useToast();
  const token = localStorage?.getItem('Authorization');
  if (!token) {
    toast.error('Вы не авторизованны');
    router.push(`/${IRouteName.Auth}`).then();
  }
};
