export enum IRouteName {
  Auth = 'auth',
  AddItem = 'add',
  EditItem = 'edit',

  Dashboard = '',
  Items = 'items',
  Orders = 'orders',
  NotStarted = 'no-started',
  Start = 'start',
  Delivery = 'delivery',
  Customers = 'customers',

  Order_Detail = 'orders/detail',
}
