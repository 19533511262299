import { createApp } from 'vue';

import App from './App.vue';
import router from './router';
import './main.scss';

// Vuetify
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { VueQueryPlugin } from '@tanstack/vue-query';
//
import Toast, { PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
//
import { initializeApp } from 'firebase/app';
import { checkAuthStatus } from '@/auth';
//

const firebaseConfig = {
  apiKey: 'AIzaSyBj60tHWwSsh0wDV2k12fVNl5WsSb9Tayk',
  authDomain: 'marketplacevost.firebaseapp.com',
  databaseURL:
    'https://marketplacevost-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'marketplacevost',
  storageBucket: 'marketplacevost.appspot.com',
  messagingSenderId: '294526058854',
  appId: '1:294526058854:web:97af8cd29e842fd27a08a7',
};

initializeApp(firebaseConfig);

const options: PluginOptions = {};

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

const app = createApp(App);

app
  .use(router)
  .use(VueQueryPlugin)
  .use(vuetify)
  .use(Toast, options)
  .mount('#app');

checkAuthStatus();
